import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {withStyles, WithStyles} from '@material-ui/core';

import style from './style';
import {addTenantToAccount} from '../../containers/UsersConfigurer/action';

type EntraAddTenantProps = WithStyles<typeof style>

function EntraAddTenant({classes}: EntraAddTenantProps) {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const tenantId = urlParams.get('tenant');
  const accountId = urlParams.get('state');
  const adminConsent = urlParams.get('admin_consent');
  const error = urlParams.get('error');

  const tenantAddedSuccess = useSelector((store: any) => store.personaReducer.tenantAddedSuccess);
  const tenantAddedMessage = useSelector((store: any) => store.personaReducer.tenantAddedMessage);

  useEffect(() => {
    if (adminConsent?.toLowerCase() === 'true' && !error) {
      dispatch(addTenantToAccount(tenantId, accountId) as never);
    }
  }, [adminConsent, error]);

  const getText = () => {
    if (error) {
      return I18n.t('addTenant.noConsent');
    }
    if (tenantAddedSuccess === null) {
      return '';
    }
    return (tenantAddedSuccess
      ? I18n.t('addTenant.success')
      : I18n.t(`serverErrors.${tenantAddedMessage}`));
  };

  return (
    <div className={classes.container}>
      <h3>{getText()}</h3>
    </div>
  );
}

export default withStyles(style)(EntraAddTenant);
