import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import {Refresh} from '@material-ui/icons';

import {CustomButton} from '../../material-dashboard-pro-react/components';
import Autocomplete from '../Autocomplete/Autocomplete';
import {
  bindDomain,
  getGroupsByDomain,
  setUpdatedConfiguredGroups,
} from '../../containers/PersonasEditForm/action';
import {getDomains} from '../../containers/UsersConfigurer/action';
import {ConfiguredGroups, NotifyModalWindow, ObservedGroups, RenderOrEmpty} from '../index';
import {ACCESS_RIGHTS, ORGANIZATION_OPERATOR_PERMISSIONS, USER_ROLES} from '../../constants';
import {RightAvailability, RightAvailabilityOrWarn} from '../../containers';

import style from './style';

/**
 * A tab with settings of persona's active directory
 * @param {classes} classes - MUI style classes object.
 * @param {domains} domains - string array of available persona's domains.
 * Each domain must have `fqdn` and `id` fields.
 * @param {isEditMode} isEditMode - if true, data can be edited.
 * @param {showDeniedBindButtons} showDeniedBindButtons - if true, bind/unbind buttons will be shown
 * even though they are denied for current user.
 */
function DirectoryServicesTab({
  classes,
  domains,
  isEditMode,
  showDeniedBindButtons,
}) {
  const entityType = 'persona';

  const accessRights = {
    bindPersona: [ACCESS_RIGHTS.PERSONA_BIND],
    unbindPersona: [ACCESS_RIGHTS.PERSONA_BIND],
  };
  const limitAccessForBindButtonsFor = [
    {
      role: USER_ROLES.ORGANIZATION_OPERATOR,
      permission: ORGANIZATION_OPERATOR_PERMISSIONS.MANAGER,
    },
  ];

  const bindingDomainId =
    useSelector((store) => store.personaEditFormReducer.directoryServices.domainId);
  const bindingDomain =
    useSelector((store) => store.personaEditFormReducer.directoryServices.domain);
  const observedGroups =
    useSelector((store) => store.personaEditFormReducer.directoryServices.adObservedGroups);
  const configuredGroups =
    useSelector((store) => store.personaEditFormReducer.directoryServices.adConfiguredGroups);
  const policies =
    useSelector((store) => store.policyReducer.policies);

  const [domain, setDomain] = useState({fqdn: bindingDomain, id: bindingDomainId});
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [modalWindowOpened, setModalWindowOpened] = useState(false);

  const dispatch = useDispatch();

  const labelProps = {
    classes: {
      root: classes.form__formControl_response,
      shrink: classes.form__shrink_response,
    },
    shrink: true,
  };

  const handleLocalDomainChange = (newDomain) => {
    setDomain({id: newDomain.value, fqdn: newDomain.label});
  };

  const handleDomainUnbinding = () => {
    dispatch(bindDomain({}));
    handleLocalDomainChange({});
  };

  const refreshDomains = () => {
    dispatch(getDomains());
  };

  const refreshGroups = () => {
    dispatch(getGroupsByDomain(domain.id));
  };

  const updateConfiguredGroups = (newGroups) => {
    dispatch(setUpdatedConfiguredGroups(newGroups));
  };

  const handleDomainBinding = () => {
    dispatch(bindDomain(domain));
    dispatch(getGroupsByDomain(domain.id));
    updateConfiguredGroups([]);
  };

  const renderDenyModalWindow = () => (
    <RenderOrEmpty isShow={modalWindowOpened}>
      <NotifyModalWindow
        text={I18n.t(
          'modalWindow.forbidAction',
          {
            entityAction: 'change AD domain for',
            entityType,
          },
        )}
        close={() => setModalWindowOpened(false)}
      />
    </RenderOrEmpty>
  );

  const getButtonBind = () => {
    if (!showDeniedBindButtons) {
      return (
        <RenderOrEmpty isShow={isEditMode}>
          <IconButton
            onClick={refreshDomains}
            size="small"
          >
            <Refresh />
          </IconButton>
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleDomainBinding}
            disabled={!domain.id}
          >
            {I18n.t('users.usersConfigurer.persona.bind')}
          </CustomButton>
        </RenderOrEmpty>
      );
    }
    return (
      <>
        <IconButton
          onClick={refreshDomains}
          size="small"
        >
          <Refresh />
        </IconButton>
        <RightAvailabilityOrWarn
          accessRights={accessRights.bindPersona}
          onClickDeny={() => setModalWindowOpened(true)}
          limitDenyFor={limitAccessForBindButtonsFor}
        >
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleDomainBinding}
            disabled={!domain.id}
          >
            {I18n.t('users.usersConfigurer.persona.bind')}
          </CustomButton>
        </RightAvailabilityOrWarn>
        {renderDenyModalWindow('bind')}
      </>
    );
  };

  const beforeBinding = () => (
    <div className={classes.rowContainer}>
      <Autocomplete
        closeMenuOnSelect={true}
        labelText={I18n.t('users.usersConfigurer.persona.directoryServices.directoryDomain')}
        inputProps={{
          className: classes.dropdown,
          onChange: handleLocalDomainChange,
          options: domains?.map((d) => ({label: d.fqdn, value: d.id})) ?? [],
          value: {label: domain.fqdn, value: domain.id},
        }}
        isDisabled={!isEditMode}
        labelProps={labelProps}
      />
      {getButtonBind()}
    </div>
  );

  const addObservedGroupToConfigured = (id) => {
    setCreatingGroup(false);
    if (!configuredGroups?.some((g) => g.id === id)) {
      updateConfiguredGroups([
        ...configuredGroups,
        {
          ...observedGroups.find((g) => g.id === id),
          policyId: policies[0]?.id,
        },
      ]);
    }
  };

  const addAllObservedGroupToConfigured = () => {
    setCreatingGroup(false);
    const configuredIds = configuredGroups?.map((g) => g.id);
    const notAddedGroups = observedGroups
      .filter((g) => !configuredIds.includes(g.id))
      .map((g) => ({...g, policyId: policies[0]?.id}));
    updateConfiguredGroups([...configuredGroups, ...notAddedGroups]);
  };

  const createManualGroup = (groupName) => {
    if (!groupName) {
      setCreatingGroup(false);
    } else if (!configuredGroups.some((g) => g.name?.toUpperCase() === groupName.toUpperCase())
        && !observedGroups.some((g) => g.name?.toUpperCase() === groupName.toUpperCase())
    ) {
      updateConfiguredGroups([
        ...configuredGroups, {name: groupName, policyId: policies[0]?.id},
      ]);
      setCreatingGroup(false);
    }
  };

  const getButtonUnbind = () => {
    if (!showDeniedBindButtons) {
      return (
        <RightAvailability
          key="block-user-action"
          accessRights={accessRights.unbindPersona}
        >
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleDomainUnbinding}
            disabled={!isEditMode}
          >
            {I18n.t('users.usersConfigurer.persona.unbind')}
          </CustomButton>
        </RightAvailability>
      );
    }

    return (
      <>
        <RightAvailabilityOrWarn
          accessRights={accessRights.unbindPersona}
          onClickDeny={() => setModalWindowOpened(true)}
          limitDenyFor={limitAccessForBindButtonsFor}
        >
          <CustomButton
            color="secondary"
            customClasses="uppercase"
            onClick={handleDomainUnbinding}
            disabled={!isEditMode}
          >
            {I18n.t('users.usersConfigurer.persona.unbind')}
          </CustomButton>
        </RightAvailabilityOrWarn>
        {renderDenyModalWindow('unbind')}
      </>
    );
  };

  const afterBinding = () => (
    <div>
      <div className={classes.header}>
        <p className={classes.header__title}>{bindingDomain}</p>
        {getButtonUnbind()}
      </div>
      <div className={classes.groupsContainer}>
        <ObservedGroups
          onAddGroupClick={addObservedGroupToConfigured}
          onAddAllClick={addAllObservedGroupToConfigured}
          refreshGroups={refreshGroups}
          groups={observedGroups}
          disabled={!isEditMode}
        />
        <ConfiguredGroups
          groups={configuredGroups}
          policies={policies}
          onNewClick={() => setCreatingGroup(true)}
          onAddManualGroup={createManualGroup}
          isCreatingManualGroup={creatingGroup}
          onGroupsChange={updateConfiguredGroups}
          disabled={!isEditMode}
        />
      </div>
    </div>
  );

  return (
    bindingDomainId ? afterBinding() : beforeBinding()
  );
}

DirectoryServicesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  domains: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  showDeniedBindButtons: PropTypes.bool,
};

DirectoryServicesTab.defaultProps = {
  showDeniedBindButtons: true,
};

export default withStyles(style)(DirectoryServicesTab);
