import initState from '../../reducers/redux-initial-state';

import {types} from './action';
import {getPersonasTableData, getUpdatedPersonaIndex} from './service';

export default (state = initState.personaReducer, action) => {
  switch (action.type) {
    case types.CHANGE_EDIT_MODE_AND_PERSONA_STATE: {
      return {
        ...state,
        isEditMode: action.isEditMode,
        personaState: action.personaState,
      };
    }
    case types.SET_DOMAINS: {
      return {
        ...state,
        domains: action.data,
      };
    }
    case types.SET_TENANTS: {
      return {
        ...state,
        tenants: action.data,
      };
    }
    case types.SET_TENANT_ADDED: {
      return {
        ...state,
        tenantAddedSuccess: action.success,
        tenantAddedMessage: action.message,
      };
    }
    case types.SET_AD_OBSERVED_GROUPS: {
      return {
        ...state,
        selectedPersona: {
          ...state.selectedPersona,
          adObservedGroups: action.groups,
        },
      };
    }
    case types.SET_PERSONAS: {
      return {
        ...state,
        personas: action.data,
      };
    }
    case types.SET_PERSONAS_TABLE_DATA: {
      return {
        ...state,
        personasTableData: getPersonasTableData(state.personas),
      };
    }
    case types.SET_PERSONA_POLICIES: {
      return {
        ...state,
        policies: action.data,
      };
    }
    case types.SET_SELECTED_PERSONA: {
      return {
        ...state,
        selectedPersona: action.data,
      };
    }
    case types.SET_SELECTED_PERSONA_AD_DOMAIN_ID: {
      return {
        ...state,
        selectedPersona: {
          ...state.selectedPersona,
          adDomainId: action.data,
        },
      };
    }
    case types.SET_SELECTED_PERSONA_INDEX: {
      return {
        ...state,
        selectedPersonaIndex: action.selectedPersonaIndex,
      };
    }
    case types.SET_UPDATED_PERSONA: {
      return {
        ...state,
        selectedPersonaIndex: getUpdatedPersonaIndex(action.data.id, state.personas),
        selectedPersona: action.data,
      };
    }
    case types.RESET_PERSONAS: {
      return {
        ...state,
        personas: initState.personaReducer.personas,
      };
    }
    case types.RESET_SELECTED_PERSONA_INDEX: {
      return {
        ...state,
        selectedPersonaIndex: initState.personaReducer.selectedPersonaIndex,
        selectedPersona: initState.personaReducer.selectedPersona,
      };
    }
    case types.RESET_PERSONA_DATA: {
      return {
        ...initState.personaReducer,
      };
    }
    default:
      return state;
  }
};
